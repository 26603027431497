import React from 'react';
import styled from 'styled-components';
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
  MagnifierContainer,
  MagnifierPreview,
  MagnifierZoom,
} from 'react-image-magnifiers';

function MagnifierImg(props) {
  let {img, style = {}} = props;

  return (
    <Wrapper style={style}>
      {/* <SideBySideMagnifier
				alwaysInPlace={true}
          // style={{ width: "100%"}}
          fillAvailableSpace={true}
					imageSrc={img}
					imageAlt="Example"
        /> */}
      <MagnifierContainer>
        <div className="example-class" style={style}>
          <MagnifierPreview imageSrc={img} overlayBoxImageSize={100} />
        </div>
        <MagnifierZoom
          alwaysInPlace={true}
          imageSrc={img}
          style={{
            position: 'absolute',
            top: 0,
            right: -20,
            zIndex: 100,
            width: 300,
            height: 300,
          }}
        />
      </MagnifierContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .example-class {
    img {
      width: ${(props) => props.style.width}px;
      height: ${(props) => props.style.height}px;
      object-fit: contain;
    }
  }
`;

export default MagnifierImg;
