import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Button, message, Tabs} from 'antd';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import qs from 'query-string';
import BreadcrumbBar from 'rev.sdk.js/Templates/ProductList/BreadcrumbBar';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import FilterMenu from 'rev.sdk.js/Templates/ProductList/FilterMenu';
import {mapLineBreak} from '../../Utils/TextUtil';
import * as AppActions from '../../AppActions';
import Carousel from '../../Components/Carousel';
import MagnifierImg from './MagnifierImg';
import {updateQueries} from '/src/Utils/updateQueries';
import {AddToReservationButton} from '../../Components/Reservation';

function ProductDetail(props) {
  const {
    pageContext: {
      collection = 'product',
      prefixPath = '/product',
      listViewPath = '/products',
      outlets = {categoryDisplayMap: 'categoryDisplayMap'},
    },
  } = props;
  const [activeSummaryTab, setActiveSummaryTab] = React.useState('intro');
  const [product, setProduct] = React.useState(null);
  const [
    productWithWatermarkImages,
    setProductWithWatermarkImages,
  ] = React.useState(null);
  const [quantity, setQuantity] = React.useState(1);
  const [currItemConfig, setCurrItemConfig] = React.useState(null);
  const [currPrice, setCurrPrice] = React.useState(null);
  const [article, setArticle] = React.useState(null);
  const [imgIdx, setImgIdx] = React.useState(0);
  const [user] = useOutlet('user');
  const showLoginModal = useOutletSetter('login-modal');
  const [dimension] = useOutlet('dimension');
  const params = qs.parse(props.location.search);
  const {id} = params;

  React.useEffect(() => {
    async function fetchData() {
      try {
        // AppActions.setLoading(true);
        const resp = await JStorage.fetchOneDocument(collection, {id});
        setProduct(resp);
        setQuantity(1);
        setImgIdx(0);
        // AppActions.setLoading(false);

        // don't show global spinner for article fetching
        if (resp.article) {
          setArticle(
            await JStorage.fetchOneDocument('Article_Default', resp.article),
          );
        }
      } catch (ex) {
        console.warn(ex);
      } finally {
        // AppActions.setLoading(false);
      }
    }
    fetchData();
  }, [id, collection]);

  React.useEffect(() => {
    async function fetchWatermarkImages() {
      let resultImages = (product.images || []).map((x) => 'loading');

      for (let i = 0; i < (product.images || []).length; i++) {
        let [img] = await AppActions.getImagesWithDealerV2(
          [product.images[i]],
          1000,
        );
        resultImages[i] = img;
        setProduct({
          ...product,
          images: [...resultImages],
        });
      }
    }

    if (
      product &&
      product.images &&
      product.images[0]?.indexOf('base64') < 0 && // workaround for keep
      user?.token // fix for token not ready issue
    ) {
      fetchWatermarkImages();
    }
  }, [product, user]);

  const onNextConfig = React.useCallback((nextItemConfig, calcResp) => {
    setCurrPrice(calcResp.amount);
    setCurrItemConfig(nextItemConfig);
  }, []);

  function renderCustomSection(sectionId, data) {
    return AppActions.renderCustomSection({
      route: prefixPath,
      sectionId,
      params,
      data,
    });
  }

  async function addToCart() {
    if (!user) {
      showLoginModal(true);
      return;
    }

    try {
      AppActions.setLoading(true);
      await Cart.addToCart(product.id, currItemConfig);
      message.success('成功');
    } catch (ex) {
      console.warn(ex);
      message.error('發生錯誤, 請稍後再重新嘗試');
    } finally {
      AppActions.setLoading(false);
    }
  }

  function updateRoute(options) {
    const nextQueryString = updateQueries({
      keys: ['cat', 'sort', 'search', 'q'],
      params,
      options,
    });

    AppActions.navigate(`/products/${nextQueryString}`);
  }

  if (!product) {
    return null;
  }

  let isCustomProduct = product?.labels?.indexOf('custom') >= 0 || false;
  let gallerySize = 550;
  if (dimension.innerWidth) {
    if (dimension.innerWidth < 450 + 20 * 2) {
      gallerySize = dimension.innerWidth - 20 * 2;
    }
  }
  let cat = '';
  return (
    <Wrapper>
      {renderCustomSection('A', {product})}

      <div className="content">
        <div style={{flexBasis: 256}}>
          <h3
            style={{
              paddingTop: 15,
              paddingBottom: 20,
              fontWeight: 600,
              letterSpacing: 2,
            }}>
            分類目錄
          </h3>
          <FilterMenu
            cat={cat}
            updateCat={(cat) => updateRoute({cat})}
            categories={'categories'}
          />
        </div>
        <div>
          {renderCustomSection('B', {product})}

          {product.labels && product.labels[0] && (
            <div style={{marginTop: 15}}>
              <BreadcrumbBar
                cat={product.labels[0]}
                updateCat={(nextCat) => {
                  navigate(`${listViewPath}?cat=${nextCat}`);
                }}
                categoryDisplayMap={outlets.categoryDisplayMap}
              />
            </div>
          )}
          <TopSection>
            <Gallery dimension={dimension} size={gallerySize}>
              {product.images && product.images[imgIdx] && (
                <Carousel
                  currIdxFromParent={imgIdx}
                  width={gallerySize}
                  height={500}
                  data={(() => {
                    let _first =
                      product.images.find((img) => img.indexOf('(') === -1) ||
                      '';
                    return product.images.sort(function (a, b) {
                      return a === _first ? -1 : b === _first ? 1 : 0;
                    });
                  })()}
                  renderPrev={null}
                  renderNext={null}
                  renderDots={null}
                  renderItem={({item}) => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                      }}>
                      {(() => {
                        if (item) {
                          if (item.indexOf('base64') < 0) {
                            return (
                              <img
                                className="carousel-img"
                                src="../../images/loading.png"
                                alt="product"
                              />
                            );
                          } else {
                            // return (
                            //   <img
                            //     className="carousel-img"
                            //     src={item}
                            //     alt="product"
                            //   />
                            // );
                            return (
                              <MagnifierImg
                                className="carousel-img"
                                img={item}
                                style={{width: gallerySize, height: 500}}
                              />
                            );
                          }
                        } else {
                          return (
                            <img
                              className="carousel-img"
                              src="../../images/empty-img.png"
                              alt="product"
                            />
                          );
                        }
                      })()}
                    </div>
                  )}
                  disableSwipe
                />
              )}

              {product.images && (
                <MiniImageList>
                  {product.images.map((image, idx) => (
                    <MiniImageItem
                      src={(() => {
                        if (image) {
                          if (image.indexOf('base64') < 0) {
                            return '../../images/loading.png';
                          } else {
                            return image;
                          }
                        } else {
                          return '../../images/empty-img.png';
                        }
                      })()}
                      alt="mini"
                      key={idx}
                      selected={idx === imgIdx}
                      onClick={() => setImgIdx(idx)}
                    />
                  ))}
                </MiniImageList>
              )}
            </Gallery>

            <div style={{flexBasis: 50}} />

            <Summary isCustom={isCustomProduct}>
              {renderCustomSection('C', {product})}
              {/* <h3>{(product.labels && product.labels[0]) || ''}</h3> */}
              <h2 style={{marginBottom: 20, marginTop: 40}}>{product.name}</h2>

              <p style={{whiteSpace: 'break-spaces', marginTop: 30}}>
                {product.description}
              </p>
              {renderCustomSection('D', {product})}
              <LineSeperator />
              {product.model && (
                <div className="row">
                  <p className="label">商品型號</p>
                  <p className="data">{product.model}</p>
                </div>
              )}
              {product.name && (
                <div className="row">
                  <p className="label">商品名稱</p>
                  <p className="data">{product.name}</p>
                </div>
              )}
              {product.gender && (
                <div className="row">
                  <p className="label">性別</p>
                  <p className="data">{product.gender}</p>
                </div>
              )}
              {product.size && (
                <div className="row">
                  <p className="label">尺寸</p>
                  <p className="data">{product.size}</p>
                </div>
              )}
              {product.width && (
                <div className="row">
                  <p className="label">寬度</p>
                  <p className="data">{product.width}</p>
                </div>
              )}
              {product.pattern && (
                <div className="row">
                  <p className="label">版型</p>
                  <p className="data">{product.pattern}</p>
                </div>
              )}
              {product.marketing && (
                <div className="row">
                  <p className="label">行銷款</p>
                  <p className="data">{product.marketing}</p>
                </div>
              )}
              {product.assortment && (
                <div className="row">
                  <p className="label">Assortment</p>
                  <p className="data">{product.assortment}</p>
                </div>
              )}
              {product['正掛'] && (
                <div className="row">
                  <p className="label">正掛</p>
                  <p className="data">{product['正掛']}</p>
                </div>
              )}
              {product.note && (
                <div className="row">
                  <p className="label">備註</p>
                  <p className="data">{product.note}</p>
                </div>
              )}

              {/* {isCustomProduct ? (
                <CustomRow>
                  {CUSTOM_ITEMS.map((item, idx) => (
                    <div key={idx}>
                      <img src={item.img} style={{height: 60}} />
                      <p style={{marginTop: 20, color: '#9a9a9a'}}>{item.name}</p>
                    </div>
                  ))}
                </CustomRow>
              ) : (
                <div>
                  <ProductVariants
                    product={product}
                    onNextConfig={onNextConfig}
                    rowStyle={{justifyContent: 'flex-start'}}
                  />
                  <LineSeperator />
                </div>
              )} */}

              <InputField style={{justifyContent: 'flex-end'}}>
                <h3 style={{color: '#6e6e6e', letterSpacing: 1}}>
                  NT.{currPrice || product.price}
                </h3>
              </InputField>

              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <AddToReservationButton product={product} />
              </div>

              {/* {isCustomProduct ? (
                <Button
                  block
                  size="large"
                  type="primary"
                  onClick={addToCart}
                  style={{marginTop: 20}}>
                  馬上去製作
                </Button>
              ) : (
                <InputField style={{flexDirection: 'row', marginTop: 30}}>
                  <Button block size="large" type="primary" onClick={addToCart}>
                    加入購物車
                  </Button>
                  <Button
                    size="large"
                    style={{
                      marginLeft: 20,
                      backgroundColor: '#f68401',
                      color: '#fff',
                      border: 0,
                    }}
                    onClick={async () => {
                      await addToCart();
                      await navigate('/checkout');
                    }}
                    block>
                    立即購買
                  </Button>
                </InputField>
              )} */}
            </Summary>
          </TopSection>

          {renderCustomSection('E', {product})}
          {/* <Tabs
            type="card"
            activeKey={activeSummaryTab}
            onChange={setActiveSummaryTab}>
            <Tabs.TabPane tab="產品介紹" key="intro">
              <div
                dangerouslySetInnerHTML={{
                  __html: mapLineBreak(product.intro),
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="產品規格" key="spec">
              <div
                dangerouslySetInnerHTML={{
                  __html: mapLineBreak(product.spec),
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="備註" key="remark">
              <div
                dangerouslySetInnerHTML={{
                  __html: mapLineBreak(product.remark),
                }}
              />
            </Tabs.TabPane>
          </Tabs>
          {article && (
            <ProductArticle>
              <RichTextPreview content={article.content} />
            </ProductArticle>
          )} */}

          {renderCustomSection('F', {product})}
        </div>
      </div>

      {renderCustomSection('G', {product})}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .content {
    max-width: var(--contentMaxWith);
    display: flex;
    margin: 0 auto;
    padding: var(--basePadding);
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const TopSection = styled.div`
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;
`;

const Gallery = styled.div`
  width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  margin-bottom: 20px;
  /* ${(props) =>
    props.dimension?.innerWidth > 1000 &&
    `
    position: sticky;
    top: calc(20px + var(--topNavBarHeight));
    `} */

  & .carousel-img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  & > img:first-child {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    object-fit: contain;
  }
`;

const CustomRow = styled.div`
  padding: 30px 0px;
  display: flex;
  justify-content: space-between;
`;

const Summary = styled.div`
  & h2 {
    text-align: center;
    font-size: 28px;
    letter-spacing: 1.5px;
    line-height: 33px;
    color: #050505;
  }
  & .row {
    display: flex;
  }
  & .label {
    font-weight: bold;
    min-width: 100px;
    margin-right: 20px;
  }
  & .data {
  }

  & h3 {
    font-size: 21px;
  }

  & p {
    font-size: 15px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0.7px;
  }

  flex: 1;
  flex-basis: 450px;
  min-height: 700px;
`;

const LineSeperator = styled.section`
  border-top: 1px solid #eee;
  margin: 20px 0;
`;

const InputField = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #0eb407;

  & > label {
    margin-right: 10px;
  }
`;

const ProductArticle = styled.div`
  min-height: 700px;
`;

const MiniImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MiniImageItem = styled.img`
  width: 64px;
  height: 64px;
  margin: 10px;
  border: 2px solid ${(props) => (props.selected ? '#ccc' : 'transparent')};
  border-radius: 4px;
  object-fit: contain;
  cursor: pointer;
`;

export default ProductDetail;
